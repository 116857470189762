import classNames from 'classnames';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { CenteredContainer } from '../components';
import {
  IconContact,
  IconCorrections,
  IconDeal,
  IconDesign,
  IconExport,
  IconReferences,
} from '../images/branding-page';
import gradientTop from '../images/gradient_top.jpg';

const Dot = ({ className }: { className?: string }) => (
  <i
    className={classNames(
      'h-[7px] w-[7px] rounded-full bg-primary md:h-[11px] md:w-[11px]',
      className
    )}
  />
);

const Ellipsis = ({
  className,
  dotClassName,
}: {
  className?: string;
  dotClassName?: string;
}) => (
  <div
    className={classNames(
      'mx-auto md:relative md:mx-0 md:mt-[20px]',
      className
    )}
  >
    <div className="inline-flex translate-x-0 flex-col gap-[12px] md:absolute md:-translate-x-1/2 md:flex-row md:gap-[19px]">
      <Dot className={dotClassName} />
      <Dot className={dotClassName} />
      <Dot className={dotClassName} />
    </div>
  </div>
);

const ANIMATION_IMAGES = 5;

interface ProcessItemProps {
  nb: number;
  title: React.ReactNode;
  text: React.ReactNode;
  numberClassName?: string;
}

const ProcessItem = ({
  nb,
  title,
  text,
  numberClassName,
}: ProcessItemProps) => (
  <div className={`flex basis-full flex-col items-center`}>
    <div
      className={classNames(
        'flex h-[53px] w-[53px] items-center justify-center rounded-full bg-primary text-3xl font-semibold text-white',
        numberClassName
      )}
    >
      {nb}
    </div>
    <h6 className="mt-4 text-center font-secondary text-lg font-semibold md:mt-8 md:text-2xl">
      {title}
    </h6>
    <p className="mt-4 text-center font-secondary text-sm md:mt-8 md:text-lg">
      {text}
    </p>
  </div>
);

export interface CollaborationItemProps {
  icon: React.ReactNode;
  title: string;
  text: React.ReactNode;
  iconWrapClass?: string;
}

const COLLABORATION_ITEMS_ICONS = [
  IconContact,
  IconDeal,
  IconDesign,
  IconCorrections,
  IconExport,
  IconReferences,
];

const CollaborationItem = ({
  icon,
  title,
  text,
  iconWrapClass,
}: CollaborationItemProps) => {
  return (
    <div className="relative">
      <div
        className={classNames(
          'absolute left-6 flex h-[76px] w-[76px] items-center justify-center bg-white shadow-[0px_3px_6px_#00000029] md:h-[162px] md:w-[168px]',
          iconWrapClass
        )}
      >
        {icon}
      </div>
      <div className="mt-6 h-[calc(100%-1.5rem)] bg-[color:rgb(var(--gray-light-1))] px-6 pb-6 pt-14 md:pt-36">
        <h4 className="mt-8 font-secondary text-base font-semibold text-header md:text-lg">
          {title}
        </h4>
        <p className="mt-4 text-sm md:text-base">{text}</p>
      </div>
    </div>
  );
};

const BrandingPage = () => {
  const { t } = useTranslation();
  const [animationImgIndex, setAnimationImgIndex] = useState(0);

  useLayoutEffect(() => {
    let handle: number | undefined = window.setInterval(() => {
      setAnimationImgIndex((prev) =>
        prev === ANIMATION_IMAGES - 1 ? 0 : prev + 1
      );
    }, 7e3);

    return () => {
      window.clearInterval(handle);
      handle = undefined;
    };
  }, []);

  const collaborationItems = useMemo(() => {
    const items = [];
    for (let i = 1; i <= 6; i++) {
      items.push(
        <CollaborationItem
          key={i}
          icon={React.createElement(COLLABORATION_ITEMS_ICONS[i - 1], {
            class: 'w-1/2 md:w-full',
          })}
          title={t(`branding.section-3.items.${i}.title`)}
          text={
            <span
              dangerouslySetInnerHTML={{
                __html: t(`branding.section-3.items.${i}.text`),
              }}
            />
          }
          iconWrapClass={i % 2 === 0 ? 'bg-secondary text-white' : ''}
        />
      );
    }
    return items;
  }, [t]);

  return (
    <>
      <Helmet>
        <title>{t('branding.page-title')}</title>
      </Helmet>
      <section className="relative">
        <div
          className="absolute bottom-0 -z-10 h-full w-full bg-cover bg-right"
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 100%), url("${gradientTop}")`,
          }}
          aria-hidden="true"
        ></div>
        <div className="h-[295px] md:hidden">
          <StaticImage
            className="h-full"
            src="../images/branding-page/banner_branding_mobile.png"
            alt=""
            aria-hidden="true"
          />
        </div>
        <CenteredContainer className="relative">
          <div className="gap-4 md:grid md:grid-cols-2">
            <div className="z-10">
              <h1 className="font-secondary text-3xl font-semibold text-header md:mt-28 md:text-5xl md:leading-tight">
                {t('branding.section-1.header')}
              </h1>
              <h4 className="mt-4 text-sm font-semibold text-header md:mt-9 md:text-2xl">
                {t('branding.section-1.subheader')}
              </h4>
              <p className="mt-4 mb-16 text-sm md:mt-8 md:text-lg">
                <Trans t={t} i18nKey="branding.section-1.text" />
              </p>
            </div>
            <div className="relative hidden md:block">
              <StaticImage
                className="!absolute top-0 bottom-0 left-[-261px] w-[9999px]"
                src="../images/branding-page/banner_branding.png"
                alt=""
                aria-hidden="true"
                objectFit="contain"
                objectPosition="left"
              />
            </div>
          </div>
        </CenteredContainer>
      </section>
      <section className="bg-[color:rgb(var(--gray-light-1))] pt-12 pb-16 md:pt-28 md:pb-20">
        <CenteredContainer>
          <h2 className="text-center font-secondary text-2xl text-header md:text-4xl">
            {t('branding.recruitment-process.header')}
          </h2>
          <p className="mx-auto mt-4 max-w-4xl text-center text-sm md:text-lg">
            {t('branding.recruitment-process.text')}
          </p>
          <div className="mt-14 grid gap-x-5 gap-y-8 md:mt-28 md:grid-cols-[1fr_auto_1fr_auto_1fr_auto_1fr]">
            <ProcessItem
              nb={1}
              title={t('branding.recruitment-process.step-1-title')}
              text={t('branding.recruitment-process.step-1-text')}
            />
            <Ellipsis />
            <ProcessItem
              nb={2}
              title={t('branding.recruitment-process.step-2-title')}
              text={t('branding.recruitment-process.step-2-text')}
            />
            <Ellipsis />
            <ProcessItem
              nb={3}
              title={t('branding.recruitment-process.step-3-title')}
              text={t('branding.recruitment-process.step-3-text')}
            />
            <Ellipsis dotClassName="bg-secondary" />
            <ProcessItem
              nb={4}
              title={t('branding.recruitment-process.step-4-title')}
              text={t('branding.recruitment-process.step-4-text')}
              numberClassName="bg-secondary"
            />
          </div>
        </CenteredContainer>
      </section>
      <section className="pb-12 md:pt-14 md:pb-28">
        <CenteredContainer className="!px-0">
          <div className="aspect-[1.54] w-full overflow-hidden">
            {animationImgIndex === 0 && (
              <StaticImage
                className="animate-[branding-effect_7.2s_ease-in-out]"
                src="../images/branding-page/img_wimiko_animation.jpg"
                alt="Branding animation frame"
              />
            )}
            {animationImgIndex === 1 && (
              <StaticImage
                className="animate-[branding-effect_7.2s_ease-in-out]"
                src="../images/branding-page/img_awumba_animation.jpg"
                alt="Branding animation frame"
              />
            )}
            {animationImgIndex === 2 && (
              <StaticImage
                className="animate-[branding-effect_7.2s_ease-in-out]"
                src="../images/branding-page/img_delpapa_animation.jpg"
                alt="Branding animation frame"
              />
            )}
            {animationImgIndex === 3 && (
              <StaticImage
                className="animate-[branding-effect_7.2s_ease-in-out]"
                src="../images/branding-page/img_kindergarten_animation.jpg"
                alt="Branding animation frame"
              />
            )}
            {animationImgIndex === 4 && (
              <StaticImage
                className="animate-[branding-effect_7.2s_ease-in-out]"
                src="../images/branding-page/img_wojs_animation.jpg"
                alt="Branding animation frame"
              />
            )}
          </div>
        </CenteredContainer>
        <CenteredContainer>
          <h2 className="mt-16 text-center font-secondary text-2xl text-header md:mt-32 md:text-4xl">
            {t('branding.section-3.header')}
          </h2>
          <p className="mx-auto mt-4 max-w-3xl text-center text-sm md:text-lg">
            {t('branding.section-3.text')}
          </p>
          <div className="mt-12 grid grid-cols-1 gap-x-7 gap-y-12 md:grid-cols-3">
            {collaborationItems}
          </div>
        </CenteredContainer>
      </section>
      <section className="mb-10 md:mb-32">
        <CenteredContainer>
          <article className="flex grid-cols-2 flex-col-reverse md:grid md:gap-8">
            <div className="relative pt-6 pb-0 md:pt-16 md:pb-16">
              <div className="absolute top-0 bottom-0 right-[-140px] left-[-99999px] -z-10 hidden bg-[color:rgb(var(--gray-light-1))] md:block" />
              <h6 className="text-semibold text-xs text-secondary md:text-lg">
                {t('branding.section-4.brandbook-headline')}
              </h6>
              <h4 className="mt-2 font-secondary text-2xl font-semibold text-header md:mt-6 md:text-4xl">
                {t('branding.section-4.brandbook')}
              </h4>
              <p className="mt-4 text-sm md:mt-6 md:text-base">
                <Trans
                  t={t}
                  i18nKey="branding.section-4.brandbook-description"
                />
              </p>
              <div className="mt-6 mb-9 md:mt-11 md:mb-9">
                <a className="button-primary mr-2 md:mr-4" href="#contact-form">
                  {t('branding.section-4.order')}
                </a>
              </div>
            </div>
            <div className="flex items-center">
              <StaticImage
                className="mt-6"
                src="../images/branding-page/brandbook.jpg"
                alt="Branding - picture"
              />
            </div>
          </article>
        </CenteredContainer>
      </section>
    </>
  );
};

export default BrandingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
